
















import { FileRepository } from '@wellbeingapp/shared/src/repositories/entities/fileRepository'
import { RepositoryFactory } from '@wellbeingapp/shared/src/repositories/repositoryFactory'
import { defineComponent, ref, watch } from '@vue/composition-api'
import useToast from '../../composables/useToast'

export default defineComponent({
  name: 'file-upload',
  props: {
    value: String,
    imageId: String,
    imageUrl: String
  },
  setup (props, context) {
    const file = ref<File>()
    const toast = useToast(context.root)
    const image = ref<any>()

    watch(file, value => {
      if (file.value && file.value.size > 5 * 1024 * 1024) {
        toast.send('File too big (Bigger than 5MB).', 'danger')
      } else {
        if (file.value) {
          upload(file.value)
        }
      }
    })

    const download = (imageId: string) => {
      if (imageId) {
        RepositoryFactory.get(FileRepository).getFileInBase64(imageId).then((res) => {
          image.value = res
        })
      }
    }

    const upload = (file: File) => {
      RepositoryFactory.get(FileRepository).uploadFile(file).then((res) => {
        context.emit('input', res.id)
        /// Next 2 lines are for modyfing the url so the mobile app can download the logo pre login
        const modifiedUrl = res.url.replace(/download/g, 'download_logo')
        context.emit('url', modifiedUrl)
        download(res.id)
      })
    }
    if (props.imageId) {
      download(props.imageId)
    }

    const deleteImage = (imageId: number) => {
      image.value = null
      context.emit('input', image.value)
    }

    return {
      deleteImage,
      image,
      file
    }
  }
})
