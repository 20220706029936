import { BaseEntityModel } from './baseEntityModel'
import EntityModel from '../../interfaces/entityModel'

export type TenantModelStorageType = {
  id?: string,
  name?: string
  slug?: string
  backgroundcolor?: string
  primarycolor?: string
  secondarycolor?: string
  baseUrl?: string
  apiSuffix?: string
  oidc_clientId?: string
  oidc_realm?: string
  oidc_redirectUrl?: string
  autoDiscoverUrl?: string
  logoUrl?: string
  activationCode?: string
  schedule_daily_start_time?: string
  notification_time?: string,
  logo?: string
  admin_email?: string
}

export default class TenantModel
  extends BaseEntityModel
  implements EntityModel<TenantModel> {
  constructor (
    public id?: string,
    public slug?: string,
    public name?: string,
    public primarycolor?: string,
    public secondarycolor?: string,
    public backgroundcolor?: string,
    public apiSuffix?: string,
    public autoDiscoverUrl?: string,
    public baseUrl?: string,
    public oidc_clientId?: string,
    public oidc_realm?: string,
    public oidc_redirectUrl?: string,
    public logoUrl?: string,
    public activationCode?: string,
    public logo?: string,
    public schedule_daily_start_time?: string,
    public admin_email?: string,
    public notification_time?: string,
    public oidc_clientSecret?: string
  ) {
    super(slug)
  }

  public static deserialize (input: any): TenantModel {
    return new TenantModel(
      input.id,
      input.slug,
      input.name,
      input.primarycolor,
      input.secondarycolor,
      input.backgroundcolor,
      input.apiSuffix,
      input.autoDiscoverUrl,
      input.baseUrl,
      input.oidc_clientId,
      input.oidc_realm,
      input.oidc_redirectUrl,
      input.logoUrl,
      input.activationCode,
      input.logo,
      input.schedule_daily_start_time,
      input.admin_email,
      input.notification_time,
      input.oidc_clientSecret
    )
  }

  public serialize (): Object {
    return {
      name: this.name,
      slug: this.slug,
      activationCode: this.activationCode,
      primarycolor: this.primarycolor,
      secondarycolor: this.secondarycolor,
      backgroundcolor: this.backgroundcolor,
      logo: this.logo,
      autoDiscoverUrl: this.autoDiscoverUrl,
      schedule_daily_start_time: this.schedule_daily_start_time,
      admin_email: this.admin_email,
      notification_time: this.notification_time,
      oidc_realm: this.oidc_realm,
      oidc_redirectUrl: this.oidc_redirectUrl,
      oidc_clientId: this.oidc_clientId,
      oidc_clientSecret: this.oidc_clientSecret,
      logoUrl: this.logoUrl
    }
  }
}
