import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class LanguageModel extends BaseEntityModel implements EntityModel<LanguageModel> {
  constructor (
    public name?: string,
    public code?: string,
    public description?: string
  ) {
    super(code)
  }

  public static deserialize (input: any): LanguageModel {
    return new LanguageModel(
      input.name,
      input.code,
      input.description
    )
  }

  public serialize (): Object {
    return {
      code: this.code,
      name: this.name
    }
  }
}
