import axios, { AxiosResponse } from 'axios'

export class StaticFileRepository {

  public getFile (fileUrl: string): Promise<any> {
    return axios.get(process.env.BASE_URL + fileUrl, {
      responseType: 'json'
    }).then(function (result: AxiosResponse) {
      // Only return the data of response
      return result.data
    })
  }
}
