













































import { defineComponent, ref, watch, PropType, computed } from '@vue/composition-api'
import getValidationState from '../../composables/useValidationState'

export enum inputTypes {
    text = 'text',
    textarea = 'textarea',
    email = 'email',
    time = 'time',
    number = 'number'
}

export default defineComponent({
  name: 'text-input',
  props: {
    value: [String, Number],
    label: String,
    type: {
      type: String as PropType<inputTypes>,
      default: inputTypes.text
    },
    id: String,
    disabled: Boolean,
    textarea: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => { return { required: true, min: 3 } }
    },
    prepend: String,
    append: String,
    rows: { // Accepting rows as a prop
      type: Number,
      default: 2 // Default value if not specified
    }
  },
  setup (props, { emit }) {

    const input = ref(props.value)
    const showError = computed(() => {
      // Assuming input is a ref to your input's value
      return props.rules.required && !input.value
    })

    const errorMessage = ''// Customize as needed

    watch(input, value => {
      emit('input', value)
    })

    watch(() => props.value, (newValue) => {
      input.value = newValue
    })

    return {
      getValidationState,
      input,
      showError,
      errorMessage
    }
  }
})
