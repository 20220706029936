import ReportModel from '@wellbeingapp/shared/src/models/entities/reportModel'
import { BaseRepository } from '@wellbeingapp/shared/src/repositories/baseRepository'

export default class ReportRepository extends BaseRepository<ReportModel> {
    id= 'reports'
    endpoint= '/reports/'
    entityModel= ReportModel
    publicGet= false

    exportQuestionsReport (report: ReportModel): Promise<any> {
      return this.post(this.endpoint + 'raw_result/', report, { responseType: 'blob' })
    }
}
