import Serializable from '../../interfaces/serializable'
import ApiConfig from './apiConfig'
import FeaturesConfig from './featuresConfig'
import OidcConfig from './oidcConfig'

export class MasterConfig implements Serializable<MasterConfig> {
  api!: ApiConfig
  oidc!: OidcConfig
  features!: FeaturesConfig

  deserialize (input: any) {
    if (input.api) {
      this.api = new ApiConfig().deserialize(input.api)
    }
    if (input.oidc) {
      this.oidc = new OidcConfig().deserialize(input.oidc)
    }
    if (input.features) {
      this.features = new FeaturesConfig().deserialize(input.features)
    }

    return this
  }
}
