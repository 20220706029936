import TenantModel from './entities/tenantModel'

export class UserModel {

  constructor (
    public id?: string,
    public firstName?: string,
    public lastName?: string,
    public permissions: Array<string> = [],
    public email?: string,
    public notificationToken?: string,
    public date_joined?: string,
    public dashboard_tenants?: Array<TenantModel>
  ) {
    return this
  }

  public static deserialize (input: any): UserModel {
    return new UserModel(
      input.id,
      input.first_name,
      input.last_name,
      input.permissions,
      input.email,
      input.notification_push_token,
      input.date_joined,
      input.dashboard_tenants
    )
  }

  public serialize (): Object {

    return this.notificationToken === undefined
      ? { }
      : {
        notification_push_token: this.notificationToken ? this.notificationToken : ''
      }
  }

}
