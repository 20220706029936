















import Vue from 'vue'
import { useRouter } from '../../composables/useRouter'
// import SelectInput from '../inputs/SelectInput.vue'

export default Vue.extend({
  name: 'custom-header',
  components: {
    // 'select-input': SelectInput
  },
  setup () {
    const { pageTitle } = useRouter()
    return {
      pageTitle
    }
  }
})
