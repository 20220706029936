













































































































































































































































import { reactive, defineComponent, ref } from '@vue/composition-api'
import { useRouter } from '../../../composables/useRouter'
import TextInput, { inputTypes } from '../../inputs/TextInput.vue'
import FileUpload from '../../dashboard/fileUpload.vue'
import BaseForm from '../../../components/base-views/baseForm.vue'
import TenantModel from '@wellbeingapp/shared/src/models/entities/tenantModel'
import { TenantRepository } from '@wellbeingapp/shared/src/repositories/entities/tenantRepository'
import usePermissions from '../../../composables/usePermissions'



export default defineComponent({
  name: 'tenants-form',
  components: {
    BaseForm,
    TextInput,
    'file-upload': FileUpload
  },
  methods: {
  },
  setup (props, { emit }) {
    const redirectRoute = ref<string>('tenants-overview-screen')
    const { can } = usePermissions()
    const { route } = useRouter()
    const tenantId = route.value.params.id
    const slug = route.value.params.slug
    let defaultValue

    if (!route.value.params.data) {
      defaultValue = TenantModel.deserialize({
        id: null,
        slug: '',
        name: '',
        primarycolor: '#f58732',
        secondarycolor: '#f58732',
        backgroundcolor: '#00a7c8',
        logo: '',
        activationCode: '',
        schedule_daily_start_time: '',
        admin_email: '',
        notification_time: '',
        oidc_clientId: '',
        oidc_realm: '',
        oidc_redirectUrl: '',
        logoUrl: ''
      })
    }

    if (route.value.params.data) {
      defaultValue = route.value.params.data
    }

    const defaultForm = reactive<TenantModel>(defaultValue)
    return {
      redirectRoute,
      TenantRepository,
      defaultForm,
      inputTypes,
      TenantModel,
      tenantId,
      can,
      slug
    }
  }
})
