









import { defineComponent, ref } from '@vue/composition-api'
import { RepositoryFactory, ScheduleBlockRepository } from '@wellbeingapp/shared'
import moment from 'moment'

export default defineComponent({
  name: 'export-scheduleblock',
  props: {
    scheduleBlockId: {
      required: false,
      type: String
    },
    date: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const clicked = ref<Boolean>(false)
    const exportScheduleblock = (scheduleBlockId: string) => {
      clicked.value = true
      RepositoryFactory.get(ScheduleBlockRepository).exportScheduleBlock(scheduleBlockId).then((res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'askforce1_results_' + moment(props.date, 'dddd D MMMM YYYY').format('YYYY_MM_DD') + '.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        clicked.value = false
      })
    }

    return {
      exportScheduleblock,
      clicked
    }
  }
})
