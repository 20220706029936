import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import PageNotFound from '../components/dashboard/PageNotFound.vue'
import DemographicQuestionsOverviewScreen from '../views/overview/DemographicQuestionsOverviewScreen.vue'
import QuestionsOverviewScreen from '../views/overview/QuestionsOverviewScreen.vue'
import FeedbackOverviewScreen from '../views/overview/FeedbackOverviewScreen.vue'
import ExportOverviewScreen from '../views/overview/ExportOverviewScreen.vue'
import TipsOverviewScreen from '../views/overview/TipsOverviewScreen.vue'
import DemographicQuestion from '../views/DemographicQuestion.vue'
import AnalyticsOverviewScreen from '../views/overview/AnalyticsOverviewScreen.vue'
import TenantsOverviewScreen from '../views/overview/TenantsOverviewScreen.vue'
import Welcome from '../views/Welcome.vue'
import Calendar from '../views/Calendar.vue'
import Feedback from '../views/Feedback.vue'
import Question from '../views/Question.vue'
import Tip from '../views/Tip.vue'
import Day from '../views/Day.vue'
import Analytics from '../views/Analytics.vue'
import Tenants from '@/views/Tenants.vue'



Vue.use(VueRouter)

const EmptyRouterView = {
  template: '<router-view></router-view>'
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/welcome',
    component: EmptyRouterView,
    meta: {
      requiresOpenIdAuth: true
    },
    children: [
      {
        path: ':slug?/welcome',
        name: 'welcome',
        component: Welcome,
        meta: {
          title: 'Welcome',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/calendar/:currentDay?',
        name: 'calendar',
        component: Calendar,
        meta: {
          title: 'Calendar',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/demographic-questions-overview-screen',
        name: 'demographic-questions-overview-screen',
        component: DemographicQuestionsOverviewScreen,
        meta: {
          title: 'Demographics Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/feedback-overview-screen',
        name: 'feedback-overview-screen',
        component: FeedbackOverviewScreen,
        meta: {
          title: 'Feedback Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/feedback/:id?',
        name: 'feedback',
        component: Feedback,
        meta: {
          title: 'Feedback',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/questions-overview-screen',
        name: 'questions-overview-screen',
        component: QuestionsOverviewScreen,
        meta: {
          title: 'Questions Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/question/:id?',
        name: 'question',
        component: Question,
        meta: {
          title: 'Question',
          date: undefined,
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/demographic-question/:id?',
        name: 'demographic-question',
        component: DemographicQuestion,
        meta: {
          title: 'Demographic',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/tips-overview-screen',
        name: 'tips-overview-screen',
        component: TipsOverviewScreen,
        meta: {
          title: 'Tips Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/tip/:id?',
        name: 'tip',
        component: Tip,
        meta: {
          title: 'Tip',
          date: undefined,
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/day/:date?',
        name: 'day',
        component: Day,
        meta: {
          title: 'Day',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/export-overview-screen',
        name: 'export-overview-screen',
        component: ExportOverviewScreen,
        meta: {
          title: 'Export Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/analytics-overview-screen',
        name: 'analytics-overview-screen',
        component: AnalyticsOverviewScreen,
        meta: {
          title: 'Analytics Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/analytics/:id?',
        name: 'analytics',
        component: Analytics,
        meta: {
          title: 'Analytics',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/tenants-overview-screen',
        name: 'tenants-overview-screen',
        component: TenantsOverviewScreen,
        meta: {
          title: 'Tenants Overview',
          requiresOpenIdAuth: true
        }
      },
      {
        path: ':slug/tenants/:id?',
        name: 'tenant',
        component: Tenants,
        meta: {
          title: 'Tenants',
          requiresOpenIdAuth: true
        }
      }
      // {
      //   path: '*',
      //   name: 'page-not-found',
      //   component: PageNotFound,
      //   meta: {
      //     title: 'Page Not Found'
      //   }
      // }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.name && to.meta) {
    document.title = to.meta.title
    to.meta.date = from.params.date
    next()
  }
})

export default router
