








import QuestionsTable from '../../components/tables/questionsTable.vue'
import usePermissions from '../../composables/usePermissions'
import ReportRepository from '@wellbeingapp/shared/src/repositories/entities/reportRepository'
import { RepositoryFactory } from '@wellbeingapp/shared'
import ReportModel from '@wellbeingapp/shared/src/models/entities/reportModel'
import { defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import useToast from '../../composables/useToast'
import i18n from '../../i18n'
import { useRouter } from '../../composables/useRouter'

export default defineComponent({
  name: 'ExportQuestions',
  components: {
    'questions-table': QuestionsTable
  },
  setup (props, context) {
    const toast = useToast(context.root)
    const loading = ref<Boolean>(false)
    const { can } = usePermissions()
    const clicked = ref<Boolean>(false)
    const { route } = useRouter()
    const slug = route.value.params.slug
    const mapQuestions = (e) => {
      loading.value = true
      const report = new ReportModel(undefined, slug, [])
      e.forEach(question => {
        if (report?.questions) {
          report.questions.push(question.id)
        }
      })
      fetchReport(report)
    }

    const fetchReport = (report: ReportModel) => {
      RepositoryFactory.get(ReportRepository).exportQuestionsReport(report).then((res) => {
        if (res) {
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel'
          })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'Ruwe_Export_' + moment().format('YYYY_MM_DD') + '.xlsx'
          a.click()
          window.URL.revokeObjectURL(url)
          clicked.value = false
          loading.value = false
        } else {
          clicked.value = false
          loading.value = false
          toast.send(i18n.t('export.wrong').toString(), 'danger')
        }
      }).catch(() => {
        clicked.value = false
        loading.value = false
        toast.send(i18n.t('export.wrong').toString(), 'danger')
      })
    }

    return {
      can,
      mapQuestions,
      loading
    }
  }
})
