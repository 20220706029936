import store from '../store'
import { getModule } from 'vuex-module-decorators'
import userModule from '@/store/userModule'
import { useRouter } from './useRouter'

export type useTenantsType = {
    list: () => Array<any>,
    isTenantSelected: () => boolean,
    selectTenant: (slug: string) => string
}

export default function useTenants () : useTenantsType {
  const userStoreModule = getModule(userModule, store)
  const { route } = useRouter()

  function list () : Array<any> {
    return userStoreModule.getUser.dashboard_tenants!
  }

  function isTenantSelected () : boolean {
    if (route.value.params.slug) {
      return true
    } else {
      return false
    }
  }

  function selectTenant (slug: string) : string {
    route.value.params.slug = slug
    return slug
  }

  return {
    list,
    isTenantSelected,
    selectTenant
  }

}
