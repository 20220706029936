import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class ReportModel extends BaseEntityModel implements EntityModel<ReportModel> {
  constructor (
    public id?: string,
    public tenant?: string,
    public questions?: Array<String>
  ) {
    super(id)
  }

  public static deserialize (input: any): ReportModel {
    return new ReportModel(
      input.id,
      input.questions ? input.questions : []
    )
  }

  public serialize (): Object {
    return {
      questions: this.questions
    }
  }
}
