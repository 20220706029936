


















import TipTypeModel from '../../../../shared/src/models/entities/tipTypeModel'
import useRepository, { callTypes } from '../../composables/useRepository'
import TipModel from '../../../../shared/src/models/entities/tipModel'
import TipTypeRepository from '../../repositories/TipTypeRepository'
import { defineComponent, ref } from '@vue/composition-api'
import usePermissions from '../../composables/usePermissions'
import TipRepository from '../../repositories/TipRepository'
import TableOverview from './../semantic/TableOverview.vue'
import { useRouter } from '../../composables/useRouter'


export default defineComponent({
  name: 'tips',
  components: {
    'table-overview': TableOverview
  },
  props: {
    isSelectable: {
      type: Boolean,
      required: true
    },
    scheduleBlock: {
      type: Object,
      required: false
    },
    isFromDayView: {
      type: Boolean,
      required: false,
      default: false
    },
    activeDate: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      fields: [
        { key: 'content', sortable: false, label: this.$t('table.info') },
        { key: 'last_used', sortable: false, label: this.$t('table.last-used') },
        { key: 'updated_at', sortable: false, label: this.$t('table.last-updated') },
        { key: 'type', sortable: false, label: this.$t('table.type') },
        { key: 'tags', sortable: false, label: this.$t('table.tags') }
      ]
    }
  },
  setup (isSelectable, { emit }) {
    const { can } = usePermissions()
    const { route } = useRouter()
    const selectedTips = ref<Array<TipModel>>([])
    const slug = route.value.params.slug

    const { results, doCall } = useRepository<TipTypeModel>(
      TipTypeRepository,
      callTypes.getModelArray
    )

    doCall()

    const submit = () => {
      emit('addTips', selectedTips.value)
      selectedTips.value = []
    }

    return {
      TipRepository,
      selectedTips,
      results,
      submit,
      can,
      slug
    }
  }
})
