import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'

export default class A_translationModel extends BaseEntityModel implements EntityModel<A_translationModel> {
  constructor (
    public id?: string,
    public content?: string,
    public code?: string,
    public name?: string,
    public description?: string
  ) {
    super(id)
  }

  public static deserialize (input: any): A_translationModel {
    return new A_translationModel(
      input.id,
      input.content,
      input.code,
      input.name,
      input.description
    )
  }

  public serialize (): Object {
    return {
      content: this.content,
      code: this.code
    }
  }
}
