import EntityModel from '../../interfaces/entityModel'
import { BaseEntityModel } from './baseEntityModel'
import QuestionModel from './questionModel'
import ScheduleBlockModel from './AppScheduleBlockModel'
import A_translationModel from './a_translationModel'

export type AnswerModelStorage = {
  id?: string,
  content?: string,
  value?: number,
  a_translations?:A_translationModel []
}

export default class AnswerModel extends BaseEntityModel implements EntityModel<AnswerModel> {
  constructor (
      public id?: string,
      public label?: string,
      public result?: number,
      public a_translations: A_translationModel[] = []
  ) {
    super(id)
  }

  public static deserialize (input: any): AnswerModel {
    return new AnswerModel(
      input.id,
      input.content,
      Math.round(input.value * 100),
      input.a_translations
    )
  }

  public serialize () : Object {

    if (this.id) {
      return {
        id: this.id,
        content: this.label,
        a_translations: this.a_translations
      }
    } else {
      return {
        content: this.label,
        a_translations: this.a_translations
      }
    }
  }

  public serializeForStorage () : AnswerModelStorage {
    return {
      id: this.id,
      content: this.label,
      value: this.result,
      a_translations: this.a_translations
    }
  }

  // error:

  public isSelected (question: QuestionModel) {
    const filterdAnswers : string[] | undefined = question.answers?.filter((selectedAnswerId: string) => this.id === selectedAnswerId)

    return filterdAnswers && filterdAnswers.length > 0
  }

}

