<template>
    <div>
      <b-col cols="10" class="pr-0">
        <tenants-form />
      </b-col>
    </div>
</template>

<script>
import Vue from 'vue'
import TenantsForm from '../components/dashboard/forms/TenantsForm.vue'

export default Vue.extend({
  name: 'Tenants',
  components: {
    'tenants-form': TenantsForm
  },
  setup () {
  }
})
</script>
