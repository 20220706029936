











import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'calendar-selector',
  props: {
    name: {
      required: true,
      type: String
    },
    min: {
      required: false,
      type: String
    },
    max: {
      required: false,
      type: String
    },
    icon: {
      required: false,
      type: String,
      default: 'calendar3-event'
    }
  },
  setup (props, context) {
    const date = ref<Date>()
    const onSelect = () => {
      context.emit('selectedDate', date.value)
    }

    return {
      onSelect,
      date
    }
  }
})
