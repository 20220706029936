

















































import { ResultRepository } from '@wellbeingapp/shared/src/repositories/entities/resultRepository'
import { RepositoryFactory } from '@wellbeingapp/shared/src/repositories/repositoryFactory'
import { defineComponent, ref } from '@vue/composition-api'
import useRepository, { callTypes } from '@wellbeingapp/dashboard/src/composables/useRepository'
import { ScheduleBlockRepository } from '@wellbeingapp/shared'
import usePermissions from '../composables/usePermissions'
import DemographicConditions from './dashboard/DemographicConditions.vue'

export default defineComponent({
  name: 'questions-answers',
  components: {
    'demographic-conditions': DemographicConditions
  },
  props: {
    scheduleBlockId: {
      type: String,
      required: true
    },
    scheduleBlockInput: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { can } = usePermissions()
    const loadingAnswers = ref<boolean>(false)
    const scheduleBlock = ref<any>(props.scheduleBlockInput)
    const demographicFeature = ref<boolean>(false)
    demographicFeature.value = RepositoryFactory.getFeatures().demographic

    const getResults = () => {
      loadingAnswers.value = true
      const { doCall, result, loading } = useRepository(
        ScheduleBlockRepository,
        callTypes.getSingel,
        { id: props.scheduleBlockId }
      )
      RepositoryFactory.get(ResultRepository).getSingle({ id: props.scheduleBlockId }).then((res: any) => {
        const scheduleBlockAnswers = res
        // console.log('INPUT: ', scheduleBlock.value.questions)
        loadingAnswers.value = false

        scheduleBlock.value.questions.forEach(questionInstance => {
          if (scheduleBlockAnswers?.result?.questions) {
            scheduleBlockAnswers.result.questions.forEach(questionResult => {
              if (questionInstance.id === questionResult.id) {
                questionInstance.question.answerOptions = questionResult.answerOptions
                questionInstance.question.resultCount = questionResult.resultCount
              }
            })
          }
        })
      })

    }


    getResults()

    return {
      demographicFeature,
      scheduleBlock,
      loadingAnswers,
      can
    }
  }
})
