import Vue from 'vue'
import { Store } from 'vuex'
import axios, { AxiosRequestConfig } from 'axios'

export const buildRequestTokenInterceptorCallback = () => {
  return function (config: AxiosRequestConfig) {
    config.headers.common.Authorization = `Bearer ${Vue.$keycloak.token}`
    return config
  }
}

export function buildResponseErrorInterceptorCallback (
  errorVm: any,
  store: Store<any>,
  retryAxiosInstance?: any
) {
  if (
    errorVm.response &&
    errorVm.response.status &&
    errorVm.response.status === 401
  ) {
    try {
      // Refresh tokens and retry call
      return Vue.$keycloak.updateToken().then((newTokens: any) => {
        errorVm.response.config.headers.Authorization = `Bearer ${newTokens.accessToken}`
        // Use custom retryAxiosInstance if given
        if (retryAxiosInstance) {
          return new Promise((resolve, reject) => {
            retryAxiosInstance
              .request(errorVm.response.config)
              .then((response: any) => {
                resolve(response)
              })
              .catch((error: any) => {
                reject(error)
              })
          })
        } else {
          return new Promise((resolve, reject) => {
            axios
              .request(errorVm.response.config)
              .then((response) => {
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
          })
        }
      })
    } catch (e) {
      throw errorVm
    }
  }
  throw errorVm
}
