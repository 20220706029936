











import QuestionRepository from '@wellbeingapp/dashboard/src/repositories/QuestionRepository'
import QuestionModel from '@wellbeingapp/shared/src/models/entities/questionModel'
import usePermissions from '../../composables/usePermissions'
import { defineComponent } from '@vue/composition-api'
import { RepositoryFactory, ScheduleBlockRepository } from '@wellbeingapp/shared'
import i18n from '@/i18n'
import useToast from '@/composables/useToast'
import { useRouter } from '@/composables/useRouter'

export default defineComponent({
  name: 'retire-question',
  props: {
    question: {
      type: QuestionModel,
      required: true
    }
  },
  setup (props, context) {
    const { can } = usePermissions()
    const { route } = useRouter()
    const slug = route.value.params.slug
    const toast = useToast(context.root)
    const retireQuestion = (question: QuestionModel) => {
      const retireQ = () => {
        RepositoryFactory.get(QuestionRepository).retireQuestion(question).then(() => {
          event()
        })
      }
      // context.emit('errorRetire', i18n.t('toast-messages.retire-error')) Possibility to get bigger notification abouve the table if needed
      if (typeof question.lastUsed !== 'undefined') {
        const questionLastUsed = question.lastUsed ? question.lastUsed : null
        let qLastUseDate = new Date(question.lastUsed)
        const nowDate = new Date(Date.now())
        let questionPresentInSchedule = false
        !questionLastUsed ? retireQ() : RepositoryFactory.get(ScheduleBlockRepository).activeScheduleBlock(slug).then((res) => {
          qLastUseDate = new Date(questionLastUsed)
          res.questions.some((q) => {
            if (q.content === question.label) {
              questionPresentInSchedule = true
              return true
            }
            return false
          })
          if (!questionLastUsed) {
            retireQ()
          } else if (!questionPresentInSchedule && qLastUseDate < nowDate) {
            retireQ()
          } else if ((!questionPresentInSchedule && qLastUseDate > nowDate) || questionPresentInSchedule) {
            toast.send(i18n.t('toast-messages.retire-error').toString(), 'danger')
          }
        })
          .catch((e: { response }) => {
            if (e.response.status === 404 && qLastUseDate < nowDate) {
              retireQ()
            } else if (e.response.status === 404 && qLastUseDate > nowDate) {
              toast.send(i18n.t('toast-messages.retire-error').toString(), 'danger')
            } else {
              toast.send('Something went wrong with retiring question.', 'danger')
            }
          })
      }
    }

    const reactivateQuestion = (question: QuestionModel) => {
      RepositoryFactory.get(QuestionRepository).reactivateQuestion(question).then(() => {
        event()
      })
    }

    const event = () => {
      context.emit('reloadData', true)
    }

    return {
      reactivateQuestion,
      retireQuestion,
      can
    }
  }
})
