









import Vue from 'vue'
import TableOverview from '../../components/semantic/TableOverview.vue'
import useRepository, { callTypes } from '../../composables/useRepository'
import usePermissions from '../../composables/usePermissions'
import TenantRepository from '../../repositories/TenantRepository'
import { defineComponent, ref } from '@vue/composition-api'
import { useRouter } from '../../composables/useRouter'
import TenantModel from '../../../../shared/src/models/entities/tenantModel'

export default defineComponent({
  name: 'tenants-table',
  components: {
    'table-overview': TableOverview
  },
  props: {
    isSelectable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fields: [
        { key: 'name', sortable: true, label: this.$t('table.name') },
        { key: 'slug', sortable: true, label: this.$t('table.slug') }
      ]
    }
  },
  setup (props) {
    const { can } = usePermissions()
    const { route } = useRouter()
    const slug = route.value.params.slug

    const { results, doCall } = useRepository<TenantModel>(
      TenantRepository,
      callTypes.getModelArray
    )

    doCall()


    return {
      TenantRepository,
      can,
      slug,
      results
    }
  }
})
