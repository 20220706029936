









































import { defineComponent, ref, Ref, watch, PropType } from '@vue/composition-api'
import Multiselect from 'vue-multiselect'
import { BaseEntityModel } from '@wellbeingapp/shared/src/models/entities/baseEntityModel'
import { BaseRepository, filterObject, repoParams } from '@wellbeingapp/shared/src/repositories/baseRepository'
import getValidationState from '../../composables/useValidationState'
import useRepository, { callTypes } from '@wellbeingapp/dashboard/src/composables/useRepository'
import TagModel from '@wellbeingapp/shared/src/models/entities/tagModel'
import TagRepository from '@wellbeingapp/shared/src/repositories/entities/tagRepository'
import i18n from '../../i18n'

export default defineComponent({
  name: 'select-input',
  props: {
    label: String,
    id: String,
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    repo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository<any>>,
      default: undefined,
      required: false
    },
    searchRepo: {
      type: Function as PropType<new (...params: any[]) => BaseRepository<any>>,
      default: undefined,
      required: false
    },
    options: {
      type: [
        Object as () => Array<any>,
        Array]
    },
    rules: {
      type: Object,
      default: () => { return { required: true } }
    },
    value: {
      type: [
        Object as () => BaseEntityModel | BaseEntityModel[] | undefined,
        Array as () => BaseEntityModel | BaseEntityModel[] | undefined,
        String
      ]
    },
    filterKey: String,
    taggable: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default: i18n.t('general.select-search').toString(),
      required: false
    },
    disabled: Boolean,
    tenant: {
      type: String,
      required: false
    }
  },
  components: {
    'multi-select': Multiselect
  },
  setup (props, { emit }) {
    const input = ref<any>(props.value)
    const loadingLocal : boolean | Ref<Boolean> = false
    const optionsValue = ref<Array<any>>([])
    let debounce
    const addTag = (newTag: string) => {
      let params : any = { model: new TagModel(undefined, newTag) }
      if (props.tenant) {
        params = { model: new TagModel(undefined, newTag), tenant: props.tenant }
      }
      const { result, doCall } = useRepository(
        TagRepository,
        callTypes.create,
        params
      )

      doCall().then(() => {
        optionsValue.value.push(result.value)
        input.value.push(result.value)
      })
    }

    if (props.options) {
      optionsValue.value = props.options
    }

    watch(input, value => {
      emit('input', value)
    })

    watch(() => props.value, () => {
      input.value = props.value
    })

    enum Filters { term = 'term' }
    const filters : any = { term: { type: 'string', value: '', filterKey: 'term' } }
    let params : any = { filters: filters }

    if (props.tenant) {
      params = { filters: filters, tenant: props.tenant }
    }

    const searchChange = (event) => {
      if (props.searchable && props.searchRepo && event !== '') {
        const { results, doCall } = useRepository(
          props.searchRepo,
          callTypes.getModelArray,
          params
        )
        clearTimeout(debounce)
        debounce = setTimeout(() => {
          filters[Filters.term] = { type: 'string', value: event, filterKey: 'term' }
          doCall().then(() => {
            optionsValue.value = results.value
          })
        }, 400)
      }
    }

    return {
      input,
      loadingLocal,
      optionsValue,
      getValidationState,
      addTag,
      searchChange
    }
  }
})
