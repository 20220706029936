import { computed, getCurrentInstance, ref, Ref, watch } from '@vue/composition-api'
import { BaseEntityModel } from '@wellbeingapp/shared/src/models/entities/baseEntityModel'
import { useRepositoryType } from './useRepository'


let pageTitle: any

export const useRouter = () => {
  const vm = getCurrentInstance()

  if (!vm) {
    throw new ReferenceError('Not found vue instance.')
  }

  const route = computed(() => vm.proxy.$route)
  const router = vm.proxy.$router
  if (!pageTitle) pageTitle = ref<string>('')

  const redirectOnResult = (redirectRoute: string, repo: useRepositoryType<BaseEntityModel>, paramIdentifier: string) => {
    const routerObject = { name: redirectRoute, params: {} }
    if (paramIdentifier) {
      routerObject.params = { }
      if (repo && repo.result && repo.result.value) {
        routerObject.params[paramIdentifier] = repo.result.value.id
      }
    }

    router.push(routerObject)
  }

  const setTitleDynamic = (entity: Ref<any | undefined>) => {
    watch(() => entity.value, () => {
      if (entity.value?.title) {
        pageTitle.value = entity.value?.title
      } else {
        pageTitle.value = route?.value?.meta?.title
      }
    })
  }

  const setTitle = (input: string) => {
    pageTitle.value = input
  }

  return { route, router, redirectOnResult, setTitleDynamic, pageTitle, setTitle } as const
}
