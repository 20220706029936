import FeedbackModel from '@wellbeingapp/shared/src/models/entities/feedbackModel'
import { BaseRepository } from '@wellbeingapp/shared/src/repositories/baseRepository'

export class FeedbackRepository extends BaseRepository<FeedbackModel> {
  id= 'feedback'
  endpoint= '/feedback/'
  entityModel= FeedbackModel
  publicGet= false

  complete (feedbackId: string, prefix): Promise<any> {
    return this.post(this.endpoint + feedbackId + '/' + prefix + '/', null)
  }

  messageToUser (feedbackId: string, prefix : string, message: string, title: string): Promise<any> {
    const message_object = { message: message, title: title }
    return this.post(this.endpoint + feedbackId + '/' + prefix + '/', message_object)
  }
}
