






































































































































































































import { reactive, defineComponent, ref } from '@vue/composition-api'
import TextInput, { inputTypes } from '../../inputs/TextInput.vue'
import QuestionRepository from '../../../repositories/QuestionRepository'
import QuestionModel, { selectOptions } from '../../../../../shared/src/models/entities/questionModel'
import BaseForm from '../../../components/base-views/baseForm.vue'
import { useRouter } from '../../../composables/useRouter'
import AnswerModel from '../../../../../shared/src/models/entities/answerModel'
import usePermissions from '../../../composables/usePermissions'
import DemographicConditions from '../DemographicConditions.vue'
import { RepositoryFactory } from '@wellbeingapp/shared'
import MultiSelectInput from '../../inputs/MultiSelectInput.vue'
import MultiSelectInputLanguage from '../../inputs/MultiSelectInputLanguage.vue'
import TagRepository from '@wellbeingapp/shared/src/repositories/entities/tagRepository'
import LanguageRepository from '@wellbeingapp/shared/src/repositories/entities/languageRepository'
import A_translationModel from '@wellbeingapp/shared/src/models/entities/a_translationModel'
import LanguageModel from '@wellbeingapp/shared/src/models/entities/languageModel'

export default defineComponent({
  name: 'question-form',
  components: {
    BaseForm,
    TextInput,
    'demographic-conditions': DemographicConditions,
    'multi-select-input': MultiSelectInput,
    'multi-select-input-language': MultiSelectInputLanguage
  },
  methods: {
    multiselectCheckbox: function (type: string, event) {
      if (event.target.checked) {
        type = 'MULTISELECT'
      }
    },
    handleInput: (answer: AnswerModel, translationValue: string, languageCode: string) => {
      try {

        if (answer && answer.a_translations) {
          let translation = answer.a_translations.find(translation => translation.code === languageCode)
          if (!translation) {
            translation = new A_translationModel()
            answer.a_translations.push(translation)
          }
          translation.content = translationValue
          translation.code = languageCode
        }
      } catch (error) {
        // console.log('ERROR handleInput =================>       ', error)
      }

    },
    handleValue: (answer: AnswerModel, languageCode: string) => {
      return answer.a_translations?.find(translation => translation.code === languageCode)?.content
    }
  },
  props: {
    isFromDayView: {
      type: Boolean,
      required: false,
      default: false
    },
    formData: {
      type: Object
    }
  },
  setup (props, { emit }) {
    const inUseQTranslation = (InUse: boolean, LangID: string) => {
      if (InUse === true && LangID.length > 2) {
        return true
      } else {
        return false
      }
    }
    const inUseATranslation = (InUse: boolean, answer: AnswerModel, languageCode: string) => {
      if (answer && answer.a_translations) {
        const translation = answer.a_translations.find(translation => translation.code === languageCode)
        if (InUse === true && translation && translation.id) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
    const removeTranslations = (removedOption: LanguageModel, answer: AnswerModel) => {
      for (const a in answer) {
        if (answer[a].a_translations.length > 0) {
          for (const b in answer[a].a_translations) {
            if (answer[a].a_translations[b].code === removedOption.code) {
              answer[a].a_translations.splice(b, 1)
            }
          }
        }
      }
    }
    const redirectRoute = ref<string>('questions-overview-screen')
    const { can } = usePermissions()
    const { route } = useRouter()
    const questionId = route.value.params.id
    const selectedTags = ref<Array<any>>([])
    const demographicFeature = ref<boolean>(false)
    demographicFeature.value = RepositoryFactory.getFeatures().demographic
    const slug = route.value.params.slug

    const defaultAnswers = [
      { content: '' },
      { content: '' }
    ]
    let defaultValue
    if (!route.value.params.data) {
      defaultValue = QuestionModel.deserialize({
        id: null,
        answers: defaultAnswers,
        answerOptions: [],
        label: 'default label',
        multiple: false,
        resultCount: null,
        content: '',
        status: 'default status',
        type: { value: 'SELECT' },
        inUse: false,
        tags: [],
        q_translations: []
      })
    }
    if (route.value.params.data) {
      defaultValue = route.value.params.data
    }


    const displayIndex = (index: number) => {
      return index + 1
    }

    const extraAnswerField = (answers: AnswerModel[], inUse: boolean) => {
      if (!inUse) {
        answers.push(new AnswerModel())
      }
    }
    const removeAnswerField = (index: number, answers: Array<string>, inUse: boolean) => {
      if (answers.length > 2 && !inUse) {
        answers.splice(index, 1)
      }
    }

    const moveAnswerField = function (from, to, answers, inUse: boolean) {
      if (!inUse) {
        answers.splice(to, 0, answers.splice(from, 1)[0])
      }
    }

    const defaultForm = reactive<QuestionModel>(defaultValue)
    const form = ref(null)
    const questionAdded = (event: any) => {
      emit('questionAdded', event)
    }

    if (route?.value?.meta?.date) {
      redirectRoute.value = 'day/' + route.value.meta.date
    }
    return {
      demographicFeature,
      inputTypes,
      QuestionModel,
      QuestionRepository,
      defaultForm,
      questionId,
      slug,
      displayIndex,
      extraAnswerField,
      removeAnswerField,
      moveAnswerField,
      selectOptions,
      can,
      questionAdded,
      redirectRoute,
      selectedTags,
      TagRepository,
      LanguageRepository,
      removeTranslations,
      inUseQTranslation,
      inUseATranslation,
      form
    }
  }
})
