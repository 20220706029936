






























































































import { FeedbackRepository } from '@wellbeingapp/shared/src/repositories/entities/feedbackRepository'
import FeedbackModel from '@wellbeingapp/shared/src/models/entities/feedbackModel'
import QuestionModel from '@wellbeingapp/shared/src/models/entities/questionModel'
import useRepository, { callTypes } from '@wellbeingapp/dashboard/src/composables/useRepository'
import AnswerModel from '@wellbeingapp/shared/src/models/entities/answerModel'
import TipModel from '@wellbeingapp/shared/src/models/entities/tipModel'
import useGlobalLoading from '../../composables/useGlobalLoading'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { useRouter } from '../../composables/useRouter'
import BackButton from '../buttons/backButton.vue'
import { RepositoryFactory } from '@wellbeingapp/shared'
import usePermissions from '../../composables/usePermissions'
import useToast from '../../composables/useToast'
import i18n from '@/i18n'

export default defineComponent({
  name: 'feedback-view',
  components: {
    'back-button': BackButton
  },
  props: {
    detailedFeedbackId: {
      type: String,
      required: false
    }
  },
  setup (props, { emit, root }) {
    const { can } = usePermissions()
    const isRead = ref<boolean>()
    const feedback = ref<FeedbackModel>()
    const newQuestion = ref<any>(new QuestionModel())
    const newTip = ref<any>(new TipModel())
    const { route, router } = useRouter()
    const slug = route.value.params.slug
    const toast = useToast(root)

    const fetchFeedback = () => {
      if (props.detailedFeedbackId) {
        const { doCall, result, loading } = useRepository(
          FeedbackRepository,
          callTypes.getSingel,
          { id: props.detailedFeedbackId }
        )
        useGlobalLoading(loading)

        doCall().then(() => {
          feedback.value = result.value
          checkCheckbox()
        })
      }
    }

    fetchFeedback()

    watch(() => props.detailedFeedbackId, () => {
      fetchFeedback()
    })

    const checkCheckbox = () => {
      if (feedback?.value?.status === 'NEW') {
        isRead.value = false
      } else {
        isRead.value = true
      }
    }

    const navigateToQuestionForm = (feedback: FeedbackModel) => {
      newQuestion.value.content = feedback.content
      newQuestion.value.answerOptions = []
      if (feedback.answers) {
        feedback.answers.forEach((answer: string) => {
          const answerOption = new AnswerModel(undefined, answer, undefined)
          if (newQuestion?.value?.answerOptions) {
            newQuestion.value.answerOptions.push(answerOption)
          }
        })
        if (!feedback.isAnonymous) {
          newQuestion.value.proposed_by = feedback.proposedBy
        }
      }
      router.push({ name: 'question', params: { data: newQuestion.value, slug: slug } })
    }

    const navigateToTipForm = (input: any) => {
      newTip.value.content = input
      router.push({ name: 'tip', params: { data: newTip.value, slug: slug } })
    }

    const patchStatus = (id: string) => {
      let prefix

      if (!isRead.value) {
        prefix = 'reopen'
        toast.send(i18n.t('toast-messages.reopen').toString())
      }

      if (isRead.value) {
        prefix = 'complete'
        toast.send(i18n.t('toast-messages.complete').toString())
      }

      RepositoryFactory.get(FeedbackRepository).complete(id, prefix).then((res) => {
        if (feedback?.value?.status) {
          feedback.value.status = res.status
        }
      })
    }

    const sendNotification = (id: string, message: string, title: string) => {
      toast.send(i18n.t('feedback.sent').toString())
      const prefix : string = 'notification'

      RepositoryFactory.get(FeedbackRepository).messageToUser(id, prefix, message, title)
    }

    const previousFeedback = () => {
      emit('previousFeedback', true)
    }

    const nextFeedback = () => {
      emit('nextFeedback', true)
    }
    return {
      navigateToQuestionForm,
      navigateToTipForm,
      previousFeedback,
      nextFeedback,
      patchStatus,
      feedback,
      isRead,
      can,
      sendNotification
    }
  }
})
