








































import { defineComponent, ref, Ref, watch, PropType } from '@vue/composition-api'
import Multiselect from 'vue-multiselect'
import { BaseEntityModel } from '@wellbeingapp/shared/src/models/entities/baseEntityModel'
import { BaseRepository, filterObject, repoParams } from '@wellbeingapp/shared/src/repositories/baseRepository'
import getValidationState from '../../composables/useValidationState'
import i18n from '../../i18n'
import LanguageModel from '@wellbeingapp/shared/src/models/entities/languageModel'
import json from '@/assets/import_languages.json'

export default defineComponent({
  name: 'select-input',
  data: function () {
    return {
      languages: json.languages
    }
  },
  props: {
    label: String,
    id: String,
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    options: {
      type: [
          Object as () => Array<any>,
          Array]
    },
    rules: {
      type: Object,
      default: () => { return { required: true } }
    },
    value: {
      type: [
          Object as () => BaseEntityModel | BaseEntityModel[] | undefined,
          Array as () => BaseEntityModel | BaseEntityModel[] | undefined,
          String
      ]
    },
    filterKey: String,
    taggable: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default: i18n.t('general.select-search').toString(),
      required: false
    },
    disabled: Boolean,
    tenant: {
      type: String,
      required: false
    }
  },
  components: {
    'multi-select': Multiselect
  },
  setup (props, { emit }) {
    const removeTrans = (removedOption: LanguageModel) => {
      emit('remove', removedOption)
    }
    const input = ref<any>(props.value)
    const loadingLocal : boolean | Ref<Boolean> = false
    const optionsValue = ref<Array<any>>([])
    let debounce

    if (props.options) {
      optionsValue.value = props.options
    }

    watch(input, value => {
      emit('input', value)
    })
    return {
      input,
      loadingLocal,
      optionsValue,
      getValidationState,
      removeTrans
    }
  }
})
