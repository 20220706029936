<template>
    <div v-if="can('tenants.view_tenant')">
      <tenants-table :isSelectable="false" />
    </div>
  </template>

<script>
import Vue from 'vue'
import TenantsTable from '../../components/tables/tenantsTable'
import TenantsForm from '../../components/dashboard/forms/TenantsForm'
import usePermissions from '../../composables/usePermissions'

export default Vue.extend({
  name: 'Tenants',
  components: {
    'tenants-table': TenantsTable
  },
  setup () {
    const { can } = usePermissions()
    return {
      can
    }
  }
})
</script>
