import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import * as Sentry from '@sentry/vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import {
  MasterConfig,
  RepositoryFactory,
  StaticFileRepository
} from '@wellbeingapp/shared'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import configModule from './store/configModule'
import Keycloak from 'keycloak-js'
import {
  buildRequestTokenInterceptorCallback,
  buildResponseErrorInterceptorCallback
} from './oidc/interceptors'

import './main.scss'
import { getModule } from 'vuex-module-decorators'
import userModule from './store/userModule'
import i18n from './i18n'
import AuthRepository from '@wellbeingapp/shared/src/repositories/authRepository'
import { VueConstructor } from 'vue/types/vue'

function getGlitchTipDsn (currentEnv: string) {
  try {
    if (currentEnv === 'dev') return process.env.VUE_APP_GLITCHTIP_DSN_DEV
    if (currentEnv === 'uat') return process.env.VUE_APP_GLITCHTIP_DSN_UAT
    if (currentEnv === 'prod') return process.env.VUE_APP_GLITCHTIP_DSN_PROD
    else return ''
  } catch (error) {
    return ''
  }
}

let environment = 'start'
new StaticFileRepository().getFile('config.json').then((configFile: any) => {
  configFile = new MasterConfig().deserialize(configFile)
  if (configFile.oidc.realm.includes('-uat')) environment = 'uat'
  if (configFile.oidc.realm.includes('-prod')) environment = 'prod'
  if (configFile.oidc.realm.includes('-dev')) environment = 'dev'

  Sentry.init({
    Vue: Vue,
    dsn: getGlitchTipDsn(environment)
  })
})


Vue.config.productionTip = false
Vue.config.productionTip = false

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Load config
new StaticFileRepository().getFile('config.json').then((configFile: any) => {
  configFile = new MasterConfig().deserialize(configFile)

  const options = {
    url: configFile.oidc.url,
    realm: configFile.oidc.realm,
    clientId: configFile.oidc.clientId
  }
  const _keycloak = new Keycloak(options)
  const Plugin = {
    install (Vue) {
      Vue.$keycloak = _keycloak
    }
  }
  Plugin.install = (Vue: VueConstructor) => {
    Vue.$keycloak = _keycloak
    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get () {
          return _keycloak
        }
      }
    })
  }
  Vue.use(Plugin)


  const configStoreModule = getModule(configModule, store)
  configStoreModule.setConfig(configFile)

  RepositoryFactory.setUrl({
    baseUrl: configFile.api.baseUrl,
    apiSuffix: configFile.api.apiSuffix
  })

  RepositoryFactory.setFeatures({
    demographic: configFile.features?.demographic
      ? configFile.features?.demographic
      : false,
    feedback: configFile.features?.feedback
      ? configFile.features?.feedback
      : false
  })

  RepositoryFactory.setInterceptors(
    buildRequestTokenInterceptorCallback,
    buildResponseErrorInterceptorCallback,
    store
  )

  router.beforeEach((to: any, from: any, next: any) => {
    if (to.matched.some((record: any) => record.meta.requiresOpenIdAuth)) {
      const userStoreModule = getModule(userModule, store)
      if (Vue.$keycloak.authenticated) {
        RepositoryFactory.get(AuthRepository)
          .me()
          .then((user: any) => {
            userStoreModule.setMe(user.result).then(() => {
              next()
            })
          })
      } else {
        next()
      }
    } else {
      next()
    }
  })
  Vue.$keycloak
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false
    })
    .then(() => {
      new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App)
      }).$mount('#app')
    })
})
