import TenantModel from '@wellbeingapp/shared/src/models/entities/tenantModel'
import { BaseRepository } from '@wellbeingapp/shared/src/repositories/baseRepository'
export class TenantRepository extends BaseRepository<TenantModel> {
  id = '/tenants/'
  endpoint = '/tenants/'
  entityModel = TenantModel
  publicGet = false

  getTenantConfig (activationCode: string): Promise<any> {
    return this.get(this.endpoint + 'config/', { params: { activationCode: activationCode } }, true)
  }

  getTenantStartTime (tenant: string): Promise<any> {
    return this.get(this.endpoint + 'start/', { params: { tenant: tenant } }, true)
  }
}
