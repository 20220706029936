






















import Vue from 'vue'
import QuestionStatusRepository from '../../repositories/QuestionStatusRepository'
import QuestionRepository from '../../repositories/QuestionRepository'
import TableOverview from '../../components/semantic/TableOverview.vue'
import useRepository, { callTypes } from '../../composables/useRepository'
import { ref } from '@vue/composition-api'
import QuestionModel from '../../../../shared/src/models/entities/questionModel'
import QuestionStatusModel from '../../../../shared/src/models/entities/questionStatusModel'
import usePermissions from '../../composables/usePermissions'
import { useRouter } from '../../composables/useRouter'
import i18n from '../../i18n'

export default Vue.extend({
  name: 'questions-table',
  components: {
    'table-overview': TableOverview
  },
  props: {
    isSelectable: {
      type: Boolean,
      required: true
    },
    scheduleBlock: {
      type: Object,
      required: false
    },
    isFromDayView: {
      type: Boolean,
      required: false,
      default: false
    },
    activeDate: {
      type: String,
      required: false
    },
    isNewQuestionAddable: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedQuestionButtonText: {
      type: String,
      required: false,
      default: i18n.t('table.add-question').toString()
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isExport: {
      type: Boolean,
      required: false,
      default: false
    },
    isAnalytics: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    const fields = [
      { key: 'content', sortable: false, label: this.$t('table.question') },
      { key: 'lastUsed', sortable: false, label: this.$t('table.last-asked') }
    ]
    if (!this.isAnalytics) {
      fields.push(
        { key: 'updated_at', sortable: false, label: this.$t('table.last-updated') },
        { key: 'status', sortable: false, label: this.$t('table.status') },
        { key: 'tags', sortable: false, label: this.$t('table.tags') })
    }
    return {
      fields: fields
    }
  },
  setup (props, { emit }) {
    const { can } = usePermissions()
    const selectedQuestions = ref<Array<QuestionModel>>([])
    const { route } = useRouter()
    const slug = route.value.params.slug
    const tableKey = ref(0)
    const table = ref(null)

    const { results, doCall } = useRepository<QuestionStatusModel>(
      QuestionStatusRepository,
      callTypes.getModelArray,
      { tenant: slug }
    )

    doCall()

    const submit = () => {
      emit('addQuestions', selectedQuestions.value)
      selectedQuestions.value = []

    }

    const refreshTable = () => {
      // @ts-ignore
      table.value.refreshTable()

    }
    emit('refreshTable')

    return {
      QuestionRepository,
      results,
      selectedQuestions,
      submit,
      can,
      slug,
      tableKey,
      table,
      refreshTable
    }
  }
})
