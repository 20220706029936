










































































import TextInput, { inputTypes } from '../components/inputs/TextInput.vue'
import BaseForm from '../components/base-views/baseForm.vue'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { useRouter } from '../composables/useRouter'
import TenantModel from '@wellbeingapp/shared/src/models/entities/tenantModel'
import { TenantRepository } from '@wellbeingapp/shared/src/repositories/entities/tenantRepository'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display'

export default defineComponent({
  name: 'Welcome',
  components: {
    BaseForm,
    TextInput
  },
  setup (props, { emit }) {
    const { route } = useRouter()
    const tenant = ref<TenantModel | null>(null)
    const slug = ref(tenant?.value?.slug)

    watch(() => route.value.params.slug, (newSlug) => {
      slug.value = newSlug
      // @ts-ignore
      tenant.value = TenantModel.deserialize(JSON.parse(route.value.query.tenant))
    }, { immediate: true })

    return {
      tenant,
      slug,
      TenantRepository,
      redirectRoute: ''
    }
  }
})
