






import QuestionModel from '@wellbeingapp/shared/src/models/entities/questionModel'
import useRepository, { callTypes } from '../../composables/useRepository'
import QuestionRepository from '../../repositories/QuestionRepository'
import { defineComponent, Ref, ref } from '@vue/composition-api'
import { useRouter } from '../../composables/useRouter'


export default defineComponent({
  name: 'clone-question',
  props: {
    question: {
      type: QuestionModel,
      required: true
    }
  },
  setup (props, { emit, root }) {
    const { route, router } = useRouter()
    const newQuestion = ref<any>(new QuestionModel())
    const cloneQuestion = () => {
      const { doCall, result, loading } = useRepository(
        QuestionRepository,
        callTypes.getSingel,
        { id: props.question.id }
      )

      doCall().then(() => {
        const questionById : Ref<QuestionModel | undefined> = result
        if (newQuestion.value && questionById.value) {
          newQuestion.value.content = questionById.value.content
          newQuestion.value.isMultiple = questionById.value.isMultiple
          newQuestion.value.answerOptions = []

          if (questionById.value.answerOptions) {
            questionById.value.answerOptions.forEach(answer => {
              answer.id = undefined
              if (newQuestion.value.answerOptions) {
                newQuestion.value.answerOptions.push(answer)
              }
            })
            router.push({ name: 'question', params: { data: newQuestion.value } })
          }
        }

      })
    }

    return {
      cloneQuestion
    }
  }
})
